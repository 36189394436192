import { Snackbar } from '@/hooks/useSnackbar';
import {
  InvestmentFundingMethod,
  PREDEFINED_FUNDING_METHOD_ID,
} from './InvestmentFundingMethod.types';

export const combineCustomFundingMethodsWithPredefined = (
  investmentFundingMethods: InvestmentFundingMethod[],
  loanLabel: string
): InvestmentFundingMethod[] => [
  {
    id: PREDEFINED_FUNDING_METHOD_ID.GROSS,
    name: `Gross ${loanLabel} amount`,
    fees: [],
    isPredefined: true,
  },
  {
    id: PREDEFINED_FUNDING_METHOD_ID.NET,
    name: `Net ${loanLabel} amount`,
    fees: [],
    isPredefined: true,
  },
  ...investmentFundingMethods,
];

export const displayCannotFetchFundingMethodsSnackbar = (
  snackbar: Snackbar
) => {
  const id = 'cannot-fetch-investment-funding-methods';
  snackbar.remove(id);
  snackbar.show({
    id,
    severity: 'error',
    title: 'Cannot fetch investment funding methods.',
  });
};

export const displayCannotFetchFundingMethodsFeesSettingsSnackbar = (
  snackbar: Snackbar
) => {
  const id = 'cannot-fetch-investment-funding-methods-fees-settings';
  snackbar.remove(id);
  snackbar.show({
    id,
    severity: 'error',
    title: 'Cannot fetch investment funding methods fees configuration.',
  });
};

/**
 * Used to bypass some of logic related with custom funding methods implementation.
 * When feature gets fully implemented it will be removed.
 */
export const INVESTMENT_FUNDING_METHODS_FULLY_IMPLEMENTED = false;
