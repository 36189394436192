import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Button, Table, Title } from 'ui';
import { DocumentTextIcon, XCircleIcon } from '@heroicons/react/outline';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import FillMissingFinancialYearEndForm from '@/components/forms/FillMissingFincncialYearEndForm';
import { SNACKBAR_ONBOARDING } from '@/constants/snackbar-messages';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { usePatchOnboardingTaskStatusMutation } from '@/services/accounts/borrower';
import {
  useGetLoanProductRulesQuery,
  useGetLoanQuery,
  useUpdateLoanMutation,
} from '@/services/loans';
import { useGetLoanFacilitiesForLoanQuery } from '@/services/loans/facility';
import { useGetProductsByOriginatorIdQuery } from '@/services/products';
import {
  useCreateReportMutation,
  useDeleteReportMutation,
  useGetSchedulesQuery,
  useUpdateMultipleReportsMutation,
} from '@/services/scheduling';
import { setSnackBar } from '@/store/alerts';
import { useSelectUser } from '@/store/user/selectors';
import EditReportForm from '../../components/forms/EditReportForm';
import { mapDtoToForm } from './loan-details/AdditionalDetailsContainer';
import { AdditionalDetailsFormType } from './loan-details/AdditionalDetailsForm';
import { mapDtoToScheduleForm } from './loan-details/ScheduleContainer';
import { ScheduleFormType } from './loan-details/ScheduleForm';
import { calculateLoanAmountWithCapFees } from './utils';
import { Product } from 'kennek/interfaces/products';
import { Frequency } from 'kennek/interfaces/scheduling';
import { Cell, Column } from 'ui/types';
import { CreateLoan, Loan, LoanProductRules } from '@/interfaces/loans';
import { LoanFacility, RowLoanFacility } from '@/interfaces/loans/facility';
import { type Schedule } from '@/interfaces/schedules';

interface LoanReportingProps {
  onContinue: () => void;
  onBack: () => void;
  loanId: string;
  isReview: boolean;
  originatorExternalId: string;
  borrowerCompanyExternalId: string;
}

const LoanReporting: React.FC<LoanReportingProps> = ({
  onContinue,
  onBack,
  loanId,
  isReview,
  originatorExternalId,
  borrowerCompanyExternalId,
}) => {
  const { data: loanDetails, ...loanQuery } = useGetLoanQuery(loanId, {
    skip: !loanId,
  });
  const { loanUpper, borrowerLower } = useGetLabelsConfig();

  const user = useSelectUser();

  const { data: products, ...productsQuery } =
    useGetProductsByOriginatorIdQuery(
      { id: user?.mambuUser?.[0]?.mambuBranchEncodedKey, state: 'ACTIVE' },
      { skip: !user?.mambuUser?.[0]?.mambuBranchEncodedKey }
    );

  const dispatch = useDispatch();

  const [report, setReport] = useState<Schedule | undefined | {}>();

  const {
    data: reports,
    isLoading,
    isFetching,
    refetch,
  } = useGetSchedulesQuery(
    {
      loanId,
    },
    { skip: !loanId }
  );
  const [createReport, { isLoading: isCreatingReport }] =
    useCreateReportMutation();
  const [updateMultipleReports, { isLoading: isUpdatingMultipleReports }] =
    useUpdateMultipleReportsMutation();
  const [deleteReport] = useDeleteReportMutation();
  const [showMissingReports, setShowMissingReports] = useState(false);
  const [patchOnboardingTaskStatus, { isLoading: isPatchingStepStatus }] =
    usePatchOnboardingTaskStatusMutation();

  const isSubmitting =
    isCreatingReport ||
    isPatchingStepStatus ||
    isUpdatingMultipleReports ||
    isFetching;

  const invalidReports = useMemo(
    () =>
      reports &&
      reports.length &&
      !!reports.filter((report) => !report.frequency).length,
    [reports]
  );

  const schedule = loanDetails
    ? mapDtoToScheduleForm(loanDetails, 'ONBOARDING')
    : undefined;

  const additionalDetails = loanDetails ? mapDtoToForm(loanDetails) : undefined;

  const [updateLoan, { isLoading: isUpdatingLoan }] = useUpdateLoanMutation();

  const { data: loanProductRules, ...rulesQuery } = useGetLoanProductRulesQuery(
    { id: loanDetails?.productTypeKey },
    { skip: !loanDetails?.productTypeKey }
  );

  const { data: loanFacilitiesStored } = useGetLoanFacilitiesForLoanQuery(
    loanDetails?.encodedKey,
    {
      skip: !loanDetails?.encodedKey,
    }
  );

  const [loanFacilities, setLoanFacilities] = useState(undefined);

  useEffect(() => {
    setLoanFacilities(
      loanFacilitiesStored?.map(({ facilityId, amount }) => ({
        facilityId,
        amount,
      }))
    );
  }, [loanFacilitiesStored]);

  const annualReportsWithoutFinancialYearEndDate = useMemo(() => {
    if (!reports?.length) return [];
    return reports.filter(
      (r) => r.frequency === Frequency.ANNUAL && !r.deadline?.financialYearEnd
    );
  }, [reports]);

  const [
    fillMissingFinancialYearEndModal,
    setFillMissingFinancialYearEndModal,
  ] = useState(false);

  const onFillMissingFinancialYearEndReportsSubmit = async (data) => {
    setFillMissingFinancialYearEndModal(false);
    if (!annualReportsWithoutFinancialYearEndDate?.length) {
      return;
    }
    const updatedReportsValues = annualReportsWithoutFinancialYearEndDate.map(
      (r) => ({
        id: r._id,
        deadline: { ...r.deadline, financialYearEnd: data.financialYearEnd },
      })
    );
    try {
      await updateMultipleReports(updatedReportsValues);
      setSnackBar({
        severity: 'primary',
        title: SNACKBAR_ONBOARDING.FINANCIAL_YEAR_END_UPDATED,
      });
      updateLoanAndContinue();
    } catch (_err) {
      dispatch(
        setSnackBar({
          severity: 'error',
          title: SNACKBAR_ONBOARDING.REPORTING_UPDATE_FAILED,
        })
      );
    }
  };

  const onSubmit = () => {
    if (annualReportsWithoutFinancialYearEndDate?.length) {
      setFillMissingFinancialYearEndModal(true);
      return;
    }
    updateLoanAndContinue();
  };

  const updateLoanAndContinue = () => {
    const loan: Loan = {
      ...loanDetails,
      graceAmount: loanDetails?.graceAmount || 0,
    };

    const payload = mapFormToPayload({
      originatorExternalId,
      borrowerCompanyExternalId,
      loan,
      products,
      scheduleForm: schedule,
      additionalDetailsForm: additionalDetails,
      loanProductRules,
      loanFacilities,
    });

    updateLoan({
      ...payload,
      id: loanDetails?.encodedKey,
    })
      .unwrap()
      .then(async () => {
        dispatch(
          setSnackBar({
            severity: 'primary',
            title: SNACKBAR_ONBOARDING.LOAN_UPDATE_TITLE,
          })
        );
        if (invalidReports) {
          setShowMissingReports(true);
          return;
        }
        patchOnboardingTaskStatus({
          taskName: 'LOAN_REPORTING',
          taskStatus: 'COMPLETED',
          loanId,
        });
        onContinue();
      })
      .catch(() => {
        dispatch(
          setSnackBar({
            severity: 'error',
            title: SNACKBAR_ONBOARDING.LOAN_UPDATE_FAILED_TITLE,
          })
        );
      });
  };

  const handleSubmitReport = async (data: Schedule) => {
    if (report['_id']) {
      // TODO update report
    } else {
      createReport({
        borrowerCompanyExternalId: borrowerCompanyExternalId,
        ...{
          // FIXME: not valid field acording to types
          branchEncodedKey: originatorExternalId,
        },
        ...data,
        loanEncodedKey: loanDetails?.encodedKey,
        loanId: loanDetails?.id,
      })
        .unwrap()
        .then(() => {
          refetch();
          setReport(null);
          dispatch(
            setSnackBar({
              severity: 'primary',
              title: 'Success',
              content: SNACKBAR_ONBOARDING.REPORTING_SUCCESS,
            })
          );
        })
        .catch(() => {
          dispatch(
            setSnackBar({
              severity: 'error',
              title: SNACKBAR_ONBOARDING.REPORTING_FAILED,
            })
          );
        });
    }
  };

  useEffect(() => {
    if (showMissingReports && reports.every((report) => report.frequency)) {
      setShowMissingReports(false);
    }
  }, [showMissingReports, reports]);

  const tableColumns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row: { original } }: Cell<Schedule>) => {
          return (
            <span
              className={classNames({
                'text-error-500': !original.frequency && showMissingReports,
              })}
            >
              {original.name}
            </span>
          );
        },
        maxWidth: 170,
        minWidth: 170,
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        Cell: ({ value }: Cell<Schedule>) => (
          <span className="capitalize ">
            {value?.replaceAll('_', ' ').toLowerCase()}
          </span>
        ),
        maxWidth: 90,
        minWidth: 90,
      },
      {
        Header: 'Due Date',
        accessor: 'deadline.days',
        Cell: ({ row: { original } }: Cell<Schedule>) => {
          return (
            <span>{`${
              original?.deadline?.days
            } ${original?.deadline?.dayType?.toLowerCase()} days`}</span>
          );
        },
        maxWidth: 140,
        minWidth: 140,
      },
      {
        Header: 'Calculation Date',
        accessor: 'deadline.calculationDate',
        Cell: ({ value }: Cell<Schedule>) => {
          return (
            <span className="capitalize ">
              {value?.replaceAll('_', ' ').toLowerCase()}
            </span>
          );
        },
        maxWidth: 180,
        minWidth: 180,
      },
      {
        Header: 'Reminders',
        accessor: 'remindDaysBeforeDeadline',
        Cell: ({ value }: Cell<Schedule>) => <span>{value} days prior</span>,
        maxWidth: 120,
        minWidth: 120,
      },
    ];
  }, [showMissingReports]);

  const dropDownItems = [
    {
      text: 'Delete',
      onClickHandler: (report: Schedule) => {
        deleteReport(report._id).then(refetch);
      },
    },
  ];

  if (loanQuery.isFetching || rulesQuery.isFetching || productsQuery.isFetching)
    return (
      <div className="flex justify-center items-center">
        <LoadingAnimationPlat fitBox />
      </div>
    );

  const defaultFinancialYearEnd = reports?.length
    ? reports.find((r) => !!r.deadline?.financialYearEnd)?.deadline
        ?.financialYearEnd
    : null;
  return (
    <div>
      <Title
        title={`${loanUpper} Reporting`}
        icon={<DocumentTextIcon />}
        titleSize="lg"
        className="mt-2"
      />
      <p className="mb-4">
        Configure the report documents to monitor the {borrowerLower}&apos;s
        performance
      </p>

      <label className="relative block heading-100 select-none mb-4">
        Create the recurring reports
      </label>

      <Table.Container
        variant="compact"
        isLoading={isLoading || loanQuery.isLoading}
      >
        <Table.Header>
          <Table.Header.Left>
            <Table.Name title="Reports list" />
          </Table.Header.Left>

          <Table.Header.Right>
            <Button
              layout="ghost"
              className="w-full rounded"
              onClick={() => setReport({})}
            >
              <span className="w-full text-left">
                + Create new report request
              </span>
            </Button>
          </Table.Header.Right>
        </Table.Header>

        <Table.Body
          columns={tableColumns as unknown as Column<object>[]}
          data={
            reports
              ? [...reports].sort((a, b) => (a.name <= b.name ? -1 : 1))
              : []
          }
          emptyTableMessage="No reports available"
          verticalDotsBtn
          dropDownItems={dropDownItems}
        />
      </Table.Container>

      <EditReportForm
        showModal={!!report}
        setShowModal={() => setReport(null)}
        report={report}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmitReport}
        defaultFinancialYearEnd={defaultFinancialYearEnd}
        loanAccount={loanDetails}
        formFlow="DETAIL_VIEW"
      />
      <FillMissingFinancialYearEndForm
        showModal={!!fillMissingFinancialYearEndModal}
        setShowModal={setFillMissingFinancialYearEndModal}
        reports={annualReportsWithoutFinancialYearEndDate}
        isSubmitting={isSubmitting}
        onSubmit={onFillMissingFinancialYearEndReportsSubmit}
      />
      {showMissingReports && (
        <div className="border-error-300 bg-error-200 p-4 px-12">
          <span className="font-semibold relative">
            <XCircleIcon className="h-6 text-error-500 absolute -left-8" />
            You must complete or delete the following:
          </span>
          <ul className="list-disc ml-2">
            {reports
              ?.filter((reports) => !reports.frequency)
              .map((report) => {
                return <li key={report._id}>{report.name}</li>;
              })}
          </ul>
        </div>
      )}

      <div className="flex justify-between mt-6">
        <Button onClick={onBack} layout="ghost" disabled={isSubmitting}>
          {isReview ? 'Cancel' : 'Back'}
        </Button>

        <Button
          onClick={onSubmit}
          type="submit"
          loading={isSubmitting || isUpdatingLoan}
          disabled={
            invalidReports || isSubmitting || isLoading || isUpdatingLoan
          }
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export const mapFormToPayload = ({
  borrowerCompanyExternalId,
  originatorExternalId,
  loan,
  products,
  scheduleForm,
  additionalDetailsForm,
  loanProductRules,
  loanFacilities,
}: {
  borrowerCompanyExternalId: string;
  originatorExternalId: string;
  loan: Loan;
  products: Product[];
  scheduleForm: ScheduleFormType;
  additionalDetailsForm: AdditionalDetailsFormType;
  loanProductRules: LoanProductRules;
  loanFacilities: LoanFacility[];
}) => {
  const hasTranches = loanProductRules?.maxNumberOfTranches > 0;
  const selectedProduct = products?.find(
    (p) => p.encodedKey === loan?.productTypeKey
  );

  // FIXME: is paymentPlan needed?
  const payload: Omit<CreateLoan, 'paymentPlan'> = {
    accountHolderKey: borrowerCompanyExternalId,
    assignedBranchKey: originatorExternalId,
    assignedCentreKey: loan?.assignedCentreKey, // FIXME: is this field needed? it's only available in updates
    externalId: loan?.externalId,
    firstRepaymentDate: scheduleForm?.firstRepaymentDate,
    gracePeriod: additionalDetailsForm.gracePeriod,
    graceAmount: additionalDetailsForm.graceAmount || loan?.graceAmount || 0,
    interestRate:
      loan?.interestSettings?.interestSpread ?? loan?.interestSettings?.rate,
    defaultInterestRate: loan?.defaultInterestRate,
    loanName: loan?.loanName,
    loanAmount: calculateLoanAmountWithCapFees(
      loan,
      selectedProduct?.feesSettings?.fees
    ),
    netLoanCalculationMethod: loan?.netLoanCalculationMethod,
    interestGraceHandleMethod: scheduleForm?.interestGraceHandleMethod,
    ltv: loan?.ltv,
    gdv: loan?.gdv,
    ltc: loan?.ltc,
    gdc: loan?.gdc,
    exitFeeAmount: loan?.exitFeeAmount,
    paymentHoliday: scheduleForm?.paymentHoliday,
    rollUpDuration: scheduleForm?.rollUpDuration,
    productTypeKey: loan?.productTypeKey,
    repaymentInstallments: scheduleForm.repaymentInstallments,
    payCurrentInstallment: scheduleForm.payCurrentInstallment,
    minimumInterestPeriod: scheduleForm.minimumInterestPeriod,
    repaymentPeriodCount: scheduleForm.repaymentPeriodCount,
    interestGraceInstallments:
      loan?.interestSettings?.interestGraceInstallments,
    repaymentPeriodUnits: scheduleForm.repaymentPeriodUnits,
    periodicPayment: loanProductRules?.balloonPaymentEnabled
      ? loan.scheduleSettings.periodicPaymentAmount
      : undefined,
    balloonPaymentAmount: loanProductRules?.balloonPaymentEnabled
      ? loan.scheduleSettings.balloonPaymentAmount
      : undefined,
    penaltyRate: loanProductRules?.variableRequiredFields?.some(
      (field) => field.name === 'penaltyRate'
    )
      ? additionalDetailsForm.penaltyRate
      : undefined,
    monthlyRepaymentDay: loanProductRules?.variableRequiredFields?.some(
      (field) => field.name === 'monthlyRepaymentDay'
    )
      ? scheduleForm.monthlyRepaymentDay
      : undefined,
    fees: loan?.disbursementDetails?.fees,
    disbursementDate: hasTranches ? undefined : scheduleForm?.disbursementDate,
    tranches: hasTranches
      ? scheduleForm?.tranches?.map((tranche) => ({
          facilities: Object.values(tranche?.facilities).map(
            (facility: Partial<RowLoanFacility>) => {
              return {
                facilityId: facility.facilityId,
                amount: parseFloat(facility.amount),
                name: facility.name,
              } as Partial<LoanFacility>;
            }
          ),
          amount: tranche.disbursementAmount
            ? tranche.disbursementAmount
            : undefined,
          disbursementDate: tranche.disbursementDate,
          fees: Object.entries(tranche?.fees ?? {}).map(
            ([predefinedFeeEncodedKey, content]) => ({
              predefinedFeeEncodedKey,
              amount: content.amount,
            })
          ),
          counterpartyId: tranche.counterpartyId,
        }))
      : undefined,
    factorAmount: loan?.factorAmount,
    interestCapitalisationFrequency:
      loan?.interestSettings?.interestCapitalisationFrequency,
    loanFacilities,
    feeSettings: loan?.feeSettings,
    counterpartyId: scheduleForm?.counterpartyId,
    interestCeiling: loan?.interestSettings?.interestCeiling,
    interestFloor: loan?.interestSettings?.interestFloor,
    useInitialInterestRateAsCeiling:
      loan?.interestSettings?.initialInterestRateAsCeiling,
    useInitialInterestRateAsFloor:
      loan?.interestSettings?.initialInterestRateAsFloor,
  };

  return payload as CreateLoan;
};

export { LoanReporting };
