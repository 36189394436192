import { SubmitHandler, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { SvgIcon } from 'kennek/icons';
import { Button, FormInput, Input, Title } from 'ui';
import { ROUTES } from '@/constants/routes';
import {
  SNACKBAR_ONBOARDING,
  SNACKBAR_TASKS,
} from '@/constants/snackbar-messages';
import { onCustomInformationSaved } from '@/features/edit-loan/editLoanSlice';
import { CustomInformationResponse } from '@/features/onboarding/custom-information/utils';
import { useSnackbar } from '@/hooks/useSnackbar';
import { usePatchOnboardingTaskStatusMutation } from '@/services/accounts/borrower';
import { useUpdateCustomInformationMutation } from '@/services/loans';
import { useAppDispatch } from '@/store/hooks';
import { getRouterQuery } from '@/utils/helpers';
import { isEmpty } from '@/utils/validation';
import {
  LeaseEstateCustomInfoForm,
  LeaseEstateCustomInfoProps,
  customFieldsVisibility,
  emptyLeaseEstateCustomInformation,
} from './lease-state.utils';
import { UserTypes } from 'kennek/interfaces/accounts';

export const LeaseEstate = ({
  onBack,
  onContinue,
  loanId,
  initialValues,
  isEdit = false,
  refetch,
  isRestructure = false,
}: LeaseEstateCustomInfoProps) => {
  const mappedDataToKeyValues = Object.fromEntries(
    initialValues?.map(({ key, value }) => [key, value])
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LeaseEstateCustomInfoForm>({
    defaultValues: !isEmpty(mappedDataToKeyValues)
      ? mappedDataToKeyValues
      : emptyLeaseEstateCustomInformation,
  });

  const router = useRouter();
  const snackbar = useSnackbar();
  const dispatch = useAppDispatch();

  const [patchOnboardingTaskStatus, { isLoading: isPatchingTaskStatus }] =
    usePatchOnboardingTaskStatusMutation();

  const [
    updateCustomInformationData,
    { isLoading: isUpdatingCustomInformationData },
  ] = useUpdateCustomInformationMutation();

  const onSubmit: SubmitHandler<LeaseEstateCustomInfoForm> = (
    values: LeaseEstateCustomInfoForm
  ) => {
    const data = Object.entries(values).map(([key, value]) => {
      const visibility: UserTypes[] =
        customFieldsVisibility.find((item) => item.key === key)?.visibility ||
        [];
      return {
        key,
        value,
        visibility: visibility,
      };
    });

    const customInformation: CustomInformationResponse = {
      loanId,
      data,
    };

    const path = getRouterQuery(ROUTES.LOAN_DETAILS, {
      loanId,
    });

    if (isRestructure) {
      dispatch(onCustomInformationSaved(data));
      snackbar.show({
        severity: 'success',
        title: SNACKBAR_ONBOARDING.CUSTOM_INFORMATION_SUCCESS,
      });
      onContinue?.();
      return;
    }

    updateCustomInformationData({
      loanId: loanId,
      payload: customInformation,
    })
      .unwrap()
      .then(() => {
        snackbar.show({
          severity: 'success',
          title: SNACKBAR_ONBOARDING.CUSTOM_INFORMATION_SUCCESS,
        });
        if (!isEdit) handleContinue();
        else router.push(path);
        refetch();
      })
      .catch(() => {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_ONBOARDING.CUSTOM_INFORMATION_FAILED_SAVE,
        });
      });
  };

  const handleContinue = () => {
    patchOnboardingTaskStatus({
      taskName: 'CUSTOM_INFORMATION',
      taskStatus: 'COMPLETED',
      loanId,
    })
      .unwrap()
      .then(() => onContinue?.())
      .catch(() => {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_TASKS.UPDATE_FAILED_TITLE,
        });
      });
  };

  return (
    <div className="flex flex-col gap-8">
      <Title
        title="Additional details"
        icon={<SvgIcon name="StatementIcon" />}
        titleSize="lg"
        className="mt-2 mb-1"
      />
      <form
        className="form stepper-form"
        onSubmit={(e) => e.preventDefault()}
        autoComplete="off"
      >
        <div className="form-input">
          <span className="mb-4 font-semibold">Visible to Borrower</span>
          <FormInput
            label="Residual value"
            type="number"
            leftSymbol="£"
            placeholder="Residual value"
            errors={errors?.residualValue?.message}
            {...register('residualValue', {
              required: 'This field cannot be empty',
            })}
          />

          <FormInput
            label="Higher upfront payment"
            type="number"
            leftSymbol="£"
            placeholder="Higher upfront payment"
            errors={errors?.higherUpfrontPayment?.message}
            {...register('higherUpfrontPayment', {
              required: 'This field cannot be empty',
            })}
          />

          <FormInput
            label="Progressive payment schedule"
            type="number"
            leftSymbol="%"
            placeholder="Progressive payment schedule"
            errors={errors?.progressivePaymentSchedule?.message}
            {...register('progressivePaymentSchedule', {
              required: 'This field cannot be empty',
            })}
          />
          <div>
            <div className="relative block heading-100 select-none">
              <label className="flex items-center gap-x-1 mb-[5px]">
                Tax on value added
              </label>
            </div>
            <div className="flex gap-4 mb-4 mt-2">
              <div className="flex gap-1">
                <Input
                  {...register('tvaOrNot', {
                    validate: (current) => typeof current === 'string',
                  })}
                  id="tvaOrNotYes"
                  type="radio"
                  value="Yes"
                />
                <label
                  className="pl-2 heading-100 text-neutral-900"
                  htmlFor="tvaOrNot"
                >
                  Yes
                </label>
              </div>
              <div className="flex gap-1">
                <Input
                  {...register('tvaOrNot')}
                  id="tvaOrNotNo"
                  type="radio"
                  value="No"
                />
                <label
                  className="pl-2 heading-100 text-neutral-900"
                  htmlFor="tvaOrNotNo"
                >
                  No
                </label>
              </div>
            </div>
          </div>

          <FormInput
            label="Comment"
            placeholder="Comment"
            errors={errors?.comment?.message}
            {...register('comment', {
              required: 'This field cannot be empty',
            })}
          />
        </div>
        <div className="form-input">
          <span className="mb-4 font-semibold">Only visible to you</span>
          <div className="mb-4">
            <FormInput
              label="Estimated worth"
              type="number"
              leftSymbol="£"
              errors={errors?.estimatedWorth?.message}
              placeholder="Estimated worth"
              bottomLabel="Optional"
              {...register('estimatedWorth')}
            />
          </div>
          <div className="mb-4">
            <FormInput
              type="date"
              label="Date estimated"
              errors={errors?.estimatedWorth?.message}
              bottomLabel="Optional"
              {...register('dateEstimated')}
            />
          </div>
          <div className="mb-4">
            <FormInput
              type="date"
              label="Estimated by"
              errors={errors?.dateEstimated?.message}
              bottomLabel="Optional"
              {...register('estimatedBy')}
            />
          </div>
          <div className="mb-4">
            <FormInput
              label="Free text"
              placeholder="Free text"
              errors={errors?.freeText?.message}
              bottomLabel="Optional"
              {...register('freeText')}
            />
          </div>

          <div className="mb-4">
            <FormInput
              type="date"
              label="Date of construction"
              errors={errors?.dateOfConstruction?.message}
              bottomLabel="Optional"
              {...register('dateOfConstruction')}
            />
          </div>

          <div className="mb-4">
            <FormInput
              type="date"
              label="Date of renovation (if present)"
              errors={errors?.dateOfRenovation?.message}
              bottomLabel="Optional"
              {...register('dateOfRenovation')}
            />
          </div>

          <div className="mb-4">
            <FormInput
              type="number"
              label="Surface of plot"
              errors={errors?.surfaceOfPlot?.message}
              placeholder="Surface of plot"
              bottomLabel="Optional"
              {...register('surfaceOfPlot')}
            />
          </div>

          <div className="mb-4">
            <FormInput
              type="number"
              label="Built surface"
              placeholder="Built surface"
              errors={errors?.dateOfRenovation?.message}
              bottomLabel="Optional"
              {...register('builtSurface')}
            />
          </div>
        </div>

        <div className={`flex ${isEdit ? 'justify-end' : 'justify-between'}`}>
          {!isEdit && (
            <Button layout="ghost" className="mt-6" onClick={onBack}>
              Back
            </Button>
          )}
          <Button
            className="mt-6 items-end"
            loading={isUpdatingCustomInformationData || isPatchingTaskStatus}
            onClick={handleSubmit(onSubmit)}
          >
            {isEdit ? 'Save Changes' : 'Save and continue'}
          </Button>
        </div>
      </form>
    </div>
  );
};
