import { InvestmentFundingMethodFeeSettings } from '@/features/onboarding/investments/funding-methods/InvestmentFundingMethod.types';
import { rootApi } from '../rootApi';
import { loansBaseApi } from './index';
import { InvestmentFundingMethod } from '@/interfaces/loans/queries';

const investmentFundingMethodsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getInvestmentFundingMethods: builder.query<
      InvestmentFundingMethod[],
      { branchEncodedKey: string; loanIdOrEncodedKey?: string }
    >({
      query: (params) => ({
        url: `${loansBaseApi}/investment/funding-methods`,
        params: {
          branchEncodedKey: params.branchEncodedKey,
          loanIdOrEncodedKey: params.loanIdOrEncodedKey,
        },
      }),
      providesTags: ['Loans', 'Investment-Funding-Methods'],
    }),
    getInvestmentFundingMethodsFeesSettings: builder.query<
      InvestmentFundingMethodFeeSettings[],
      { branchEncodedKey: string }
    >({
      query: (params) => ({
        url: `${loansBaseApi}/investment/funding-methods/fees-settings`,
        method: 'GET',
        params: { branchEncodedKey: params.branchEncodedKey },
      }),
      providesTags: ['Loans', 'Investment-Funding-Methods'],
    }),
  }),
});

export const {
  useGetInvestmentFundingMethodsQuery,
  useGetInvestmentFundingMethodsFeesSettingsQuery,
} = investmentFundingMethodsApi;
