import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { useGetOnboardingTaskStatusesQuery } from '@/services/accounts/borrower';
import { useGetLoanQuery } from '@/services/loans';
import { useGetBorrowerCompanyByCompanyExternalIdQuery } from '@/services/originator/borrower-company';
import { useGetProductsByOriginatorIdQuery } from '@/services/products';
import { useSelectUser } from '@/store/user/selectors';
import type { BackendSteps } from '../Onboarding';
import {
  useSelectLoanFacilities,
  useSelectLoanInformationForm,
} from '../onboardingSlice';
import { calculateLoanAmountWithCapFees } from '../utils';
import type {
  LoanInformationFormType,
  LoanInformationProps,
} from './LoanInformationForm';
import { LoanInformationForm } from './LoanInformationForm';
import type { Company } from 'kennek/interfaces/originator';
import { Product } from 'kennek/interfaces/products';
import type { Loan, OnboardingTasks } from '@/interfaces/loans';
import { IndexInterestRateTypeDto } from '@/interfaces/loans';

type ContainerProps = Pick<
  LoanInformationProps,
  'onContinue' | 'onBack' | 'isReview'
> & {
  borrowerCompanyExternalId: string;
  loanId: string | undefined;
  borrowerEmail: string;
  setProductKey?: (prevState: string) => void;
  enableGroupedLoans: boolean;
};

const LoanInformationContainer = ({
  loanId,
  borrowerEmail,
  borrowerCompanyExternalId,
  setProductKey,
  enableGroupedLoans,
  ...props
}: ContainerProps) => {
  const { data: onboardingTasks = [], ...onboardingTasksQuery } =
    useGetOnboardingTaskStatusesQuery(
      { loanId, borrowerEmail },
      { skip: !loanId }
    );

  const { data: company, ...companyQuery } =
    useGetBorrowerCompanyByCompanyExternalIdQuery(
      { borrowerCompanyExternalId },
      {
        skip: !borrowerCompanyExternalId,
      }
    );

  const { data: loan, ...loanQuery } = useGetLoanQuery(loanId, {
    skip: !loanId,
  });
  const user = useSelectUser();

  const { data: products, isSuccess: productsFetched } =
    useGetProductsByOriginatorIdQuery(
      { id: user?.mambuUser?.[0]?.mambuBranchEncodedKey, state: 'ACTIVE' },
      { skip: !user?.mambuUser?.[0]?.mambuBranchEncodedKey }
    );

  const productsTypeOptions =
    products?.map((product) => ({
      value: product.encodedKey,
      label: product.name,
    })) || [];

  const loanInformationForm = useSelectLoanInformationForm();
  const loanFacilities = useSelectLoanFacilities();

  const initialFormValues = loanInformationForm
    ? loanInformationForm
    : loan
      ? mapDtoToLoanInformationForm({ loan, company, products })
      : EMPTY_FORM;
  if (
    (onboardingTasksQuery.isFetching &&
      onboardingTasksQuery.currentData === undefined) ||
    (companyQuery.isFetching && companyQuery.currentData === undefined) ||
    (loanQuery.isFetching && loanQuery.currentData === undefined)
  )
    return (
      <div className="flex justify-center items-center">
        <LoadingAnimationPlat fitBox />
      </div>
    );

  return (
    <LoanInformationForm
      setProductKey={setProductKey}
      company={company}
      disableLoanType={isBackendTaskCompleted(onboardingTasks, 'LOAN_DETAILS')}
      formFlow="ONBOARDING"
      initialFormValues={initialFormValues}
      initialLoanFacilities={loanFacilities}
      productsTypeOptions={productsTypeOptions}
      productsFetched={productsFetched}
      products={products}
      borrowerEmail={borrowerEmail}
      enableGroupedLoans={enableGroupedLoans}
      {...props}
    />
  );
};

export const mapDtoToLoanInformationForm = ({
  loan,
  company,
  products,
}: {
  loan: Loan;
  company: Company;
  products: Product[];
}): LoanInformationFormType => {
  const fees = loan?.disbursementDetails?.fees?.reduce(
    (acc, { predefinedFeeEncodedKey, amount }) => ({
      ...acc,
      [predefinedFeeEncodedKey]: { amount },
    }),
    {}
  );
  const selectedProduct = products?.find(
    (p) => p.encodedKey === loan?.productTypeKey
  );
  const interestRateSource = loan?.interestSettings?.indexRateType;
  const isIndexInterestRate =
    interestRateSource === IndexInterestRateTypeDto.INDEX;
  const interestRate = isIndexInterestRate
    ? loan?.interestSettings?.interestSpread
    : loan?.interestSettings?.displayedInterestRate;

  return {
    externalId: loan?.externalId,
    interestRate,
    defaultInterestRate: loan?.defaultInterestRate,
    loanAmount: calculateLoanAmountWithCapFees(
      loan,
      selectedProduct?.feesSettings?.fees
    ),
    loanName: loan?.loanName
      ? loan?.loanName
      : company?.name && !loan?.productTypeKey
        ? company?.name
        : 'Company',
    productTypeKey: loan?.productTypeKey,
    fees,
    netLoanCalculationMethod: loan?.netLoanCalculationMethod,
    ltv: loan?.ltv,
    gdv: loan?.gdv,
    ltc: loan?.ltc,
    gdc: loan?.gdc,
    exitFeeAmount: loan?.exitFeeAmount,
    factorAmount: loan?.factorAmount,
    interestCapitalisationFrequency:
      loan?.interestSettings?.interestCapitalisationFrequency,
    feeAmortizationDetails: loan?.feeSettings?.feeAmortizationDetails,
    interestCeiling:
      loan?.interestSettings?.initialInterestRateAsCeiling === true
        ? null
        : loan?.interestSettings?.interestCeiling,
    interestFloor:
      loan?.interestSettings?.initialInterestRateAsFloor === true
        ? null
        : loan?.interestSettings?.interestFloor,
    useInitialInterestRateAsCeiling:
      loan?.interestSettings?.initialInterestRateAsCeiling,
    useInitialInterestRateAsFloor:
      loan?.interestSettings?.initialInterestRateAsFloor,
    periodicPayment: loan?.scheduleSettings?.periodicPaymentAmount?.toString(),
    balloonPaymentAmount:
      loan?.scheduleSettings?.balloonPaymentAmount?.toString(),
    balloonPaymentPercentage:
      !!loan?.scheduleSettings?.balloonPaymentPercentage &&
      loan?.scheduleSettings?.balloonPaymentPercentage * 100,
  };
};

export const EMPTY_FORM: LoanInformationFormType = {
  loanName: '',
  loanAmount: null,
  interestRate: null,
  defaultInterestRate: null,
  productTypeKey: '',
  externalId: '',
  fees: {},
  exitFeeAmount: null,
  interestCapitalisationFrequency: null,
  feeAmortizationDetails: null,
};

export { LoanInformationContainer };

const isBackendTaskCompleted = (
  onboardingTasks: OnboardingTasks[],
  taskName: BackendSteps
) => {
  return (
    onboardingTasks?.find((task) => task.name === taskName)?.status ===
    'COMPLETED'
  );
};
