import { useEffect, useState } from 'react';
import { Autocomplete } from 'ui';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { useGetCurrentUserApprovedLoans } from '@/hooks/useGetCurrentUserApprovedLoans';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useSelectCompanyForm } from '../onboardingSlice';
import { filterLoans } from './GroupedLoanIdField.utils';
import {
  ApprovedLoanSearchSortBy,
  ApprovedLoanSearchSortOrder,
} from '@/interfaces/loans/queries';

type LoanIdFieldProps = {
  value: string | null;
  onChange: (newValue: string) => void;
  error: boolean;
  borrowerEmail: string;
  onSetIsValid: (isValid: boolean) => void;
};

export const GroupedLoanIdField = ({
  value,
  onChange,
  borrowerEmail,
  onSetIsValid,
  error,
}: LoanIdFieldProps) => {
  const labelsConfig = useGetLabelsConfig();
  const borrowerCompany = useSelectCompanyForm();

  const [searchTerm, setSearchTerm] = useState(value ?? '');
  useEffect(
    function updateSearchTermOnValueChange() {
      if (value) {
        setSearchTerm(value);
      }
    },
    [value]
  );
  const loansQuery = useGetCurrentUserApprovedLoans({
    page: 1,
    pageSize: 50,
    // ↑ Why is page size set to 50? The endpoint we're calling here doesn't
    // support all the filtering options we need, so we're setting the page
    // size a bit higher than normal to make sure we still have data left
    // after calling `filterLoans` to filter in the frontend.
    companyName: borrowerCompany?.name,
    sortBy: ApprovedLoanSearchSortBy.DISBURSEMENT_DATE,
    sortOrder: ApprovedLoanSearchSortOrder.DESC,
  });
  const filteredLoans = filterLoans(
    loansQuery.data?.data,
    borrowerEmail,
    searchTerm
  );

  const onChangeSearchTerm = (newSearchTerm: string) => {
    onSetIsValid(newSearchTerm === '');
    setSearchTerm(newSearchTerm);
    onChange('');
  };
  const onSelectLoan = (loanId: string): void => {
    if (loanId) {
      // ↑ check because `loanId` will be empty if the user clicks on the
      // dropdown item saying "Loading…" or "Failed to fetch"
      onSetIsValid(true);
    }
    onChange(loanId);
  };

  return (
    <label className="relative heading-100 select-none mb-3">
      Group with
      <Autocomplete
        value={searchTerm}
        onChange={onSelectLoan}
        onSearch={onChangeSearchTerm}
        results={
          loansQuery.isError
            ? [{ id: '', label: `Failed to fetch ${labelsConfig.loansLower}` }]
            : loansQuery.isFetching
              ? [{ id: '', label: 'Loading…' }]
              : (filteredLoans ?? []).map((loan) => ({
                  id: loan.id,
                  label: `${loan.name} (${loan.id})`,
                }))
        }
        error={error}
        placeholder={`${labelsConfig.loanUpper} name or ID`}
      />
      {error && (
        <ExclamationCircleIcon className="absolute text-error-500 w-5 h-5 bottom-[0.55rem] right-3" />
      )}
    </label>
  );
};
